@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;1,500&display=swap');



* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    
}

:root{
    --color-bg: #0a192f;
    --color-primary: #a8b2d1;
    --color-primary-variant: grey;

    --transition: all 400ms ease;

    --container-width-lg: 75%;

    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    
}

html{
    scroll-behavior: smooth;
}

::-webklit-scrollbar {
    display: none;
}




/* General Styles */
/* General Styles */
section {
    color: var(--color-color-primary);
    background-color: var(--color-bg);
    padding: 4rem 0;
}

@media screen and (max-width: 601px) {
    .container{
        margin: 0 auto;
        padding: 0 1.5rem;
        height: 100vh;
    }
}

@media screen and (min-width: 601px) {
    .container{
        width: var(--container-width-lg);
        margin: 0 auto;
    }

    section {
        min-height: 100vh;
    }
}

@media screen and (min-width: 850px) {
    .container{
        width: var(--container-width-lg);
        margin: 0 auto;
    }

    section {
        min-height: 100vh;
    }
}

 
h2, 
h3, 
h4,
h5 {
    font-weight: 500;
    color: #64ffda;
    
}

h1{
    font-weight: 500;
    color: white;
}

.color-primary {
    color: var(--color-primary)
}


section > h2, section > h5{
    text-align: center;
    color: var(--color-primary)
}

section > h2{
    color: #64ffda;
    margin-bottom: 3rem;
}

.text-light{
    color: var(--color-color-primary)
}

.text-black{
    color: var(--color-color-primary)
}

a {
    color: var(--color-primary);
}

a:hover {
    color: var(--color-primary)
}

.btn {
    width: max-content;
    min-width: 150px;
    display: inline-block;
    color: #64ffda;
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid #64ffda;
    transition: var(--transition);
    background: none;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;

}

.btn:hover{
    color: white;
    border-color: #64ffda;
}



.btn-primary {
    color: var(--color-primary);
    background-color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}