.header__socials-wrapper {
    width: 100%; /* Ensure the wrapper takes the full width of the viewport */
    background-color: var(--color-bg); /* Set background color */
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: var(--color-bg);
  }
.header__socials {
    display: flex;
    gap: 1rem;
    justify-content: center;
    padding: 1rem 0;
    color: #64ffda;
  }
  
  .header__socials a {
    color: inherit;
    font-size: 1.5rem;
    transition: color 0.3s, transform 0.3s;
  }
  
  .header__socials a:hover {
    color: #0077b5; 
    transform: scale(1.2);
  }
  
  .header__socials a:nth-child(2):hover {
    color: #333; 
  }

  .header__socials a:nth-child(3):hover {
    color: teal;
  }
  