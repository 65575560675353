Header {
    height: 100vh;
    padding-top: 1rem;
    overflow: hidden;
    background-color: var(--color-bg);
    color: var(--color-primary)
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
    padding: 0 1rem;
}

.cta {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
    justify-content: center;
}

.header__logo {
    text-align: left;
    left: 20px;
    top: -100px;
    position: absolute;
}

.header__socials {
    align-items: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 25%;
}

/* Updated style for the scroll-down arrow */
.scroll-down-arrow {
    position: fixed;
    bottom: 100px;
    /* Adjust the distance from the bottom as needed to clear the nav bar */
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    /* Adjust the font size as needed */
    color: #64ffda;
    /* Use the same color as your header text */
    cursor: pointer;
    transition: opacity 0.6s ease-in-out;
    /* Adjust the duration as needed */
}

.scroll-down-arrow.hidden {
    opacity: 0;
    pointer-events: none;
}

@media screen and (max-width: 606px) {
    .cta {
        flex-direction: column;
        align-items: center;
    }

    .scroll-down-arrow {
        bottom: 20px;
    }
}

/* Media Queries */

@media screen and (max-width: 606px) {
    header {
        height: 100vh;
    }

    .header__socials {
        display: none;
    }

    .header__logo {
        display: none;
    }
}