.about__cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem;
    background-color: var(--color-bg);
}

.about__card{
    text-align: center;
    padding-bottom: 10%;
    background-color: var(--color-bg);
    border-bottom-color: var(--color-primary)
}

.about__container{
    text-align: justify;
    background-color: var(--color-bg);
    color: var(--color-primary);
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem;
    background-color: var(--color-bg);
}

.about__card {
    text-align: center;
    padding-bottom: 10%;
    background-color: var(--color-bg);
    border-bottom-color: var(--color-primary);
}

.about__container {
    text-align: justify;
    background-color: var(--color-bg);
    color: var(--color-primary);
}

@media screen and (max-width: 601px) {
    .about__container {
        text-align: center;
    }
}

/* Media queries for popular devices */
@media only screen and (max-width: 768px) {
    .about__cards {
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem;
    }
}

@media only screen and (max-width: 480px) {
    .about__cards {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    
}

@media only screen and (max-width: 900px) {
    .about__container {
        padding-bottom: 100%;
    }
}
