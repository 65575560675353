nav {
    width: max-content;
    display: block;
    padding: 0.7rem, 1.7rem;
    z-index: 5;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(20px);
    background: transparent;
    border: 2px solid #64ffda
}

nav div {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    font-size: 1.1rem;
    color: var(--color-primary);
    cursor: pointer;
}

nav div:hover{
    background: #233554;
    color: #64ffda;
    border-color: #64ffda; 
}

@media screen and (max-width: 601px) {
    nav {
        display: none;
    }
    
}