/* Container styles */

.experience__intro {
    text-align: center;
    margin-bottom: 2rem;
}


.experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 1rem;
    /* Add horizontal padding to ensure content doesn't touch edges */
}

/* Box styles */
.experience__container>div {
    background: transparent;
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 2px solid #64ffda;
}

/* Heading styles */
.experience__container>div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

/* Content styles */
.experience__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 3rem;
}

/* Details styles */
.experience__details {
    display: flex;
    gap: 1rem;
    color: var(--color-primary);
}

/* Details icon styles */
.experience__details-icon {
    margin-top: 10px;
    color: var(--color-primary);
}

/* Media queries for popular devices */
@media only screen and (max-width: 601px) {
    .experience__container {
        grid-template-columns: 1fr;
        gap: 1rem;
        padding: 0;
    }

    .experience__container>div {
        padding: 2rem;
    }
}

@media only screen and (max-width: 1200px) {
    .experience__container {
        grid-template-columns: 1fr;
    }
}